import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

function checkAuth(from, to, next) {
  if (store.getters.isAuthenticated) {
    next()
  } else {
    next('/login')
  }
}

const routes = [
  {
    path: '*',
    name: '/',
    component: () => import('../views/Home.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login.vue')
  },
  {
    path: '/worldwide',
    name: 'WorldWide',
    component: () => import('../views/WorldWide.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/postlist',
    name: 'PostList',
    component: () => import('../views/PostList.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/editpost',
    name: 'EditPost',
    component: () => import('../views/EditPost.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/createpost',
    name: 'CreatePost',
    component: () => import('../views/CreatePost.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/lessonList',
    name: 'LessonList',
    component: () => import('../views/LessonList.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/editlesson',
    name: 'EditLesson',
    component: () => import('../views/EditLesson.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/createlesson',
    name: 'CreateLesson',
    component: () => import('../views/CreateLesson.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/questionlist',
    name: 'QuestionList',
    component: () => import('../views/QuestionList.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/editquestion',
    name: 'EditQuestion',
    component: () => import('../views/EditQuestion.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/createquestion',
    name: 'CreateQuestion',
    component: () => import('../views/CreateQuestion.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/userlist',
    name: 'UserList',
    component: () => import('../views/UserList.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/usercreate',
    name: 'UserAdd',
    component: () => import('../views/UserCreate.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/userupdate',
    name: 'UserUpdate',
    component: () => import('../views/UserUpdate.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/userdetail',
    name: 'UserDetail',
    component: () => import('../views/UserDetail.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/providerdetail',
    name: 'ProviderDetail',
    component: () => import('../views/ProviderDetail.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/training',
    name: 'Training',
    component: () => import('../views/TrainingDashboard.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/trainees',
    name: 'Trainees',
    component: () => import('../views/TraineeList.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/trainee',
    name: 'TraineeDetail',
    component: () => import('../views/TraineeDetail.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/serviceDirectory',
    name: 'ServiceDirectory',
    component: () => import('../views/ServiceDirectory.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/editServiceDirectory',
    name: 'EditServiceDirectory',
    component: () => import('../views/EditServiceDirectory.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/createServiceDirectory',
    name: 'CreateServiceDirectory',
    component: () => import('../views/CreateServiceDirectory.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/publicdashboard',
    name: 'PublicDashboard',
    component: () => import('../views/PublicDashboard.vue')
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('../views/Notifications.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
  {
    path: '/createnotification',
    name: 'CreateNotification',
    component: () => import('../views/CreateNotification.vue'),
    meta: { requireAuth: true },
    beforeEnter: checkAuth
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
