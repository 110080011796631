import ApiService from "@/common/api.service";
import {
  FETCH_INTERVIEW_QUESTIONSET, ADD_ACTIVITY
} from "./actions.type";
import {
  SET_INTERVIEW_QUESTIONSET, SET_ERROR, STORE_ANSWER, STORE_SALES, CLEAR_ACTIVITY
} from "./mutations.type";

const initialState = {
  questionSet: []
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_INTERVIEW_QUESTIONSET](context) {
    return new Promise(resolve => {
      ApiService.get("/v1/activity", "questions?questionnaireID=1")
        .then(({ data }) => {
          context.commit(SET_INTERVIEW_QUESTIONSET, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  async [ADD_ACTIVITY](context, activityData) {
    return new Promise(resolve => {
      ApiService.postActivity("/api/activity", activityData)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          resolve(response);
          context.commit(SET_ERROR, response);
        });
    });
  }
}

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  [SET_INTERVIEW_QUESTIONSET](state, questionData) {
    state.questionSet = questionData;
    localStorage.questionSet = JSON.stringify(questionData);
  },
  [STORE_ANSWER](state, interviewAnswer) {
    state.answers = interviewAnswer;
    localStorage.answers = JSON.stringify(interviewAnswer);
  },
  [STORE_SALES](state, sales) {
    state.sales = sales;
    localStorage.sales = JSON.stringify(sales);
  },
  [CLEAR_ACTIVITY]() {
    localStorage.removeItem("answerList");
    localStorage.removeItem("salesList");
  }
};

const getters = {
  questionSet() {
    return JSON.parse(localStorage.questionSet);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
