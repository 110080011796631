var axios = require("axios");
import { API_URL } from "@/common/config";
import JwtService from "@/common/jwt.service";

import {
  FETCH_TOTALUSERCOUNT_PUBLICREPORT,
  FETCH_TOTALUSERCOUNTFISCAL_PUBLICREPORT,
  FETCH_TEST_RESULT_COUNT_PUBLICREPORT,
  FETCH_TRAINING_STARTED_COUNT_PUBLICREPORT,
  FETCH_SUCCESS_TEST_RESULT_COUNT_PUBLICREPORT,
  FETCH_TOTAL_LECTURE_COMPLETED_COUNT_PUBLICREPORT,
  FETCH_QUESTION_CORRECT_COUNT_PUBLICREPORT,
  FETCH_PROGRAM_SPENDING_TIME_PUBLICREPORT
} from "./actions.type";

import {
} from "./mutations.type";

const initialState = {
  questionList: [],
  currentOutlet: {},
};

export const state = { ...initialState };

export const actions = {
  async[FETCH_TOTALUSERCOUNT_PUBLICREPORT]() {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/public/report/totalusercount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // context.commit(SET_TOTALUSERCOUNT, response.data);
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TOTALUSERCOUNTFISCAL_PUBLICREPORT](context, payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/public/report/totalfiscalusercount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TEST_RESULT_COUNT_PUBLICREPORT]() {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/public/report/totaltestresultcount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TRAINING_STARTED_COUNT_PUBLICREPORT]() {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/public/report/totaltrainingcount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_SUCCESS_TEST_RESULT_COUNT_PUBLICREPORT]() {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/public/report/totalposttestcount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TOTAL_LECTURE_COMPLETED_COUNT_PUBLICREPORT]() {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/public/report/totallecturecompleted`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_QUESTION_CORRECT_COUNT_PUBLICREPORT](context, type) {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/public/report/totalquestioncount/${type}`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_PROGRAM_SPENDING_TIME_PUBLICREPORT]() {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/public/report/totalprogramspendingtime`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
};

export const mutations = {
  // [SET_TOTALUSERCOUNT](state, totalData) {
  //   localStorage.totalUserCount = JSON.stringify(totalData);
  // },
}


export default {
  state,
  actions
};
