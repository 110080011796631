import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import { API_URL } from "@/common/config";

const ApiService = {
  init() {
    Vue.use(VueAxios, { $myHttp: axios })
    Vue.$myHttp.defaults.baseURL = API_URL;
    Vue.$myHttp.headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
  },

  setHeader() {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.$myHttp.get(resource, params).catch(error => {
      throw new Error(`[miladycms] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    return Vue.$myHttp.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[miladycms] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.$myHttp.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.$myHttp.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.$myHttp.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.$myHttp.delete(resource).catch(error => {
      throw new Error(`[12miladycms1App] ApiService ${error}`);
    });
  }
};

export default ApiService;

