export const ARTICLE_PUBLISH = "publishArticle";
export const ARTICLE_DELETE = "deleteArticle";
export const ARTICLE_EDIT = "editArticle";
export const ARTICLE_EDIT_ADD_TAG = "addTagToArticle";
export const ARTICLE_EDIT_REMOVE_TAG = "removeTagFromArticle";
export const ARTICLE_RESET_STATE = "resetArticleState";
export const CHECK_AUTH = "checkAuth";
export const COMMENT_CREATE = "createComment";
export const COMMENT_DESTROY = "destroyComment";
export const FAVORITE_ADD = "addFavorite";
export const FAVORITE_REMOVE = "removeFavorite";
export const FETCH_ARTICLE = "fetchArticle";
export const FETCH_ARTICLES = "fetchArticles";
export const FETCH_COMMENTS = "fetchComments";
export const FETCH_PROFILE = "fetchProfile";
export const FETCH_PROFILE_FOLLOW = "fetchProfileFollow";
export const FETCH_PROFILE_UNFOLLOW = "fetchProfileUnfollow";
export const FETCH_TAGS = "fetchTags";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const FETCH_MASTERDATA = "fetchMasterData";
export const FETCH_OUTLET = "fetchOutlet";
export const FETCH_INTERVIEW_QUESTIONSET = "fetchInterviewQuestionSet";
export const ADD_ACTIVITY = "addActivity";
export const ADD_INQUIRY = "addInquiry";
export const FETCH_OUTLET_INQUIRY_DATASET = "fetchOutletInquiryDataset";
export const FETCH_POSTLIST = "fetchPostList";
export const FETCH_POST_SLUG_LIST = "fetchPostSlugList";
export const GET_POST = "getPost";
export const CREATE_POST = "createPost";
export const UPDATE_POST = "updatePost";
export const DELETE_POST = "deletePost";
export const GET_REF_DATA = "getRefData";
export const GET_REF_TRAINING_DATA = "getTrainingRefData";
export const FETCH_LESSONLIST = "fetchLessonList";
export const GET_LESSON = "getLesson";
export const GET_LESSON_DETAIL = "getLessonDetail";
export const CREATE_LESSON = "createLesson";
export const UPDATE_LESSON = "updateLesson";
export const DELETE_LESSON = "deleteLesson";
export const FETCH_LESSON_QUIZ_LIST = "getLessonQuizList";
export const FETCH_LESSON_VIDEO_LIST = "getLessonVideoList";
export const FETCH_QUESTIONLIST = "fetchQuestionList";
export const GET_QUESTION = "getQuestion";
export const GET_QUESTION_DETAIL = "getQuestionDetail";
export const CREATE_QUESTION = "createQuestion";
export const UPDATE_QUESTION = "updateQuestion";
export const DELETE_QUESTION = "deleteQuestion";
export const FETCH_QUESTION_SET_LIST = "fetchQuestionSetList";
export const FETCH_COUNTRY_LIST = "fetchCountryList";
export const FETCH_USERLIST = "getUserList";
export const FETCH_TOTALUSERCOUNT = "fetchTotalUserCount";
export const FETCH_TOTALUSERCOUNTFISCAL = "fetchTotalUserCountFiscal";
export const FETCH_USER_DETAIL = "fetchUserDetail";
export const FETCH_PROVIDER_DETAIL = "fetchProviderDetail";
export const CREATE_USER = "createUser";
export const UPDATE_USER = "updateUser";
export const DELETE_USER = "deleteUser";
export const FETCH_TRAINEE_LIST = "fetchTraineeList";
export const FETCH_TEST_RESULT_COUNT = "fetchTestResultCount";
export const FETCH_TRAINING_STARTED_COUNT = "fetchTrainingStartedCount";
export const FETCH_SUCCESS_TEST_RESULT_COUNT = "fetchSuccessTestResultCount";
export const FETCH_TOTAL_LECTURE_COMPLETED_COUNT = "fetchTotalLectureCompletedCount";
export const SET_PASSWORD = "setPassword";
export const FETCH_QUESTION_CORRECT_COUNT = "fetchQuestionCorrectCount";
export const FETCH_TRAINEE_DETAIL = "fetchTraineeDetail";
export const FETCH_PROGRAM_SPENDING_TIME = "fetchProgramSpendingTime";
export const FETCH_SERVICE_DIRECTORY = "fetchServiceDirectory";
export const GET_SERVICE_DIRECTORY = "getServiceDirectory";
export const CREATE_SERVICE_DIRECTORY = "createServiceDirectory";
export const UPDATE_SERVICE_DIRECTORY = "updateServiceDirectory";
export const DELETE_SERVICE_DIRECTORY = "deleteServiceDirectory";

export const FETCH_TOTALUSERCOUNT_PUBLICREPORT = "fetchTotalUserCountPublicReport";
export const FETCH_TOTAL_USER_COUNTRY_COUNT_PUBLIC_REPORT = "fetchTotalUserCountryCountReport";
export const FETCH_TOTALUSERCOUNTFISCAL_PUBLICREPORT = "fetchTotalUserCountFiscalPublicReport";
export const FETCH_TEST_RESULT_COUNT_PUBLICREPORT = "fetchTestResultCountPublicReport";
export const FETCH_TRAINING_STARTED_COUNT_PUBLICREPORT = "fetchTrainingStartedCountPublicReport";
export const FETCH_SUCCESS_TEST_RESULT_COUNT_PUBLICREPORT = "fetchSuccessTestResultCountPublicReport";
export const FETCH_TOTAL_LECTURE_COMPLETED_COUNT_PUBLICREPORT = "fetchTotalLectureCompletedCountPublicReport";
export const FETCH_QUESTION_CORRECT_COUNT_PUBLICREPORT = "fetchQuestionCorrectCountPublicReport";
export const FETCH_PROGRAM_SPENDING_TIME_PUBLICREPORT = "fetchProgramSpendingTimePublicReport";

export const FETCH_PROVIDER_TYPE_LIST = "fetchProviderTypeList";


export const FETCH_NOTIFICATION_LIST = 'fetchNotificationList';
export const GET_NOTIFICATION = 'getNotification';
export const CREATE_NOTIFICATION = 'createNotification';
export const CREATE_USER_NOTIFICATION = 'createUserNotification';

export const UPLOAD_MEDIA = "uploadMedia";