import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
//import { CHECK_AUTH } from "./store/actions.type"
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import ApiService from "./common/api.service";
import DateFilter from "./common/date.filter";
import ErrorFilter from "./common/error.filter";
import VueMeta from 'vue-meta'
import CKEditor from 'ckeditor4-vue';
import JsonExcel from "vue-json-excel";
// import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';  

Vue.use(CKEditor);
Vue.use(VueSweetalert2);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})


Vue.config.productionTip = false
Vue.config.devtools = true
Vue.config.debug = true
Vue.http = Vue.prototype.$http = axios
Vue.filter("date", DateFilter);
Vue.filter("error", ErrorFilter);
Vue.component("downloadExcel", JsonExcel);
ApiService.init();

// Ensure we checked auth before each page load.
// router.beforeEach((to, from, next) => {
//   Promise.all([store.dispatch(CHECK_AUTH)]).then(next)
// });

new Vue({
  vuetify,
  components: { App },
  router,
  store,
  template: '<App/>'
}).$mount('#app')
