// import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework'
// import '@mdi/font/css/materialdesignicons.css'

// Vue.use(Vuetify);

// export default new Vuetify({
//   icons: {
//     iconfont: 'mdi', // default - only for display purposes
//   }
// })


import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, { directives: { ripple } })

const theme = {
  primary: '#7e13a1',
  secondary: '#ef5da8',
  accent: '#e91e63',
  info: '#00CAE3',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#FF5252',
}

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-menu-down' },
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
