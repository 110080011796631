var axios = require("axios");
import { API_URL } from "@/common/config";
import JwtService from "@/common/jwt.service";
import {
  FETCH_SERVICE_DIRECTORY,
  GET_SERVICE_DIRECTORY,
  CREATE_SERVICE_DIRECTORY,
  UPDATE_SERVICE_DIRECTORY,
  DELETE_SERVICE_DIRECTORY
} from "./actions.type";

const initialState = {
  outletList: [],
  outletQuery: {},
  currentOutlet: {},
};

export const state = { ...initialState };

export const actions = {
  async[FETCH_SERVICE_DIRECTORY]() {
    // console.log("TOKEN: " + JwtService.getToken())
    var config = {
      method: 'get',
      url: `${API_URL}/v1/service-directory/list`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[GET_SERVICE_DIRECTORY](context, postID) {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/service-directory/detail/${postID}`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      }
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[UPDATE_SERVICE_DIRECTORY](context, payload) {
    var lessonData = payload.serviceDirectoryData;
    var config = {
      method: 'put',
      url: `${API_URL}/v1/service-directory`,
      headers: {
        //'Content-Type': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: lessonData
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[CREATE_SERVICE_DIRECTORY](context, payload) {
    var lessonData = payload.serviceDirectoryData;
    console.log(lessonData);
    var config = {
      method: 'post',
      url: `${API_URL}/v1/service-directory`,
      headers: {
        // 'Content-Type': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: lessonData
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[DELETE_SERVICE_DIRECTORY](context, payload) {
    var config = {
      method: 'delete',
      url: `${API_URL}/v1/service-directory/${payload.serviceDirectoryId}`,
      headers: {
        // 'Content-Type': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      }
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
};

const getters = {
};

export default {
  state,
  actions,
  mutations,
  getters
};
