var axios = require('axios');
import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import { API_URL } from "@/common/config";

import {
  LOGIN,
  LOGOUT,
  REGISTER,
  CHECK_AUTH,
  UPDATE_USER,
  FETCH_MASTERDATA
} from "./actions.type";
import { SET_AUTH, PURGE_AUTH, SET_ERROR, SET_MASTERDATA, REMOVE_MASTERDATA, RESET_OUTLETQUERY } from "./mutations.type";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isMasterDataExists() {
    return localStorage.getItem("teams") != undefined ? true : false;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    var data = JSON.stringify({
      "username": credentials.username,
      "password": credentials.password
    });
    var config = {
      method: 'post',
      url: `${API_URL}/admin/auth/login`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json'
      },
      data: data
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          context.commit(SET_AUTH, response.data.data);
          resolve(response)
        })
        .catch(function (error) {
          console.log(error);
          context.commit(SET_ERROR, error);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    context.commit(REMOVE_MASTERDATA);
    context.commit(RESET_OUTLETQUERY);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/v1/users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data.user);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [CHECK_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("/v1/user")
        .then(({ data }) => {
          context.commit(SET_AUTH, data.user);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = {
      email,
      username,
      bio,
      image
    };
    if (password) {
      user.password = password;
    }

    return ApiService.put("/v1/user", user).then(({ data }) => {
      context.commit(SET_AUTH, data.user);
      return data;
    });
  },
  [FETCH_MASTERDATA](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/v1/masterdata")
        .then(({ data }) => {
          context.commit(SET_MASTERDATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response);
        });
    })
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    localStorage.user = JSON.stringify(user);
    JwtService.saveToken(user.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_MASTERDATA](state, masterdata) {
    localStorage.teams = JSON.stringify(masterdata.teams);
    localStorage.products = JSON.stringify(masterdata.products);
    localStorage.brands = JSON.stringify(masterdata.brands);
    localStorage.outletStatuses = JSON.stringify(masterdata.outletStatuses);
    localStorage.ratings = JSON.stringify(masterdata.ratings);
    localStorage.period = JSON.stringify(masterdata.period);
    localStorage.townships = JSON.stringify(masterdata.townships);
    localStorage.cities = JSON.stringify(masterdata.cities);
    localStorage.outletcategories = JSON.stringify(masterdata.categories);
  },
  [REMOVE_MASTERDATA]() {
    localStorage.clear();
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
