<template>
  <div>
    <v-app-bar
      height="60px"
      absolute
      color="#1CA1B3"
      dark
      shrink-on-scroll
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>

      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>Milady CMS - v{{ appVersion }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon to="/">
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn icon @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <!--
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->

      <!-- <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="n in 5" :key="n" @click="() => {}">
            <v-list-item-title>Option {{ n }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item to="/worldwide">
            <v-list-item-icon>
              <v-icon>mdi-box-shadow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>World Wide (excluding Myanmar)</v-list-item-title>
          </v-list-item>

          <v-list-item to="/postlist">
            <v-list-item-icon>
              <v-icon>mdi-box-shadow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Post List</v-list-item-title>
          </v-list-item>

          <v-list-item to="/lessonList">
            <v-list-item-icon>
              <v-icon>mdi-box-shadow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Lessons</v-list-item-title>
          </v-list-item>

          <v-list-item to="/questionList">
            <v-list-item-icon>
              <v-icon>mdi-box-shadow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Questions</v-list-item-title>
          </v-list-item>

          <v-list-item to="/userList">
            <v-list-item-icon>
              <v-icon>mdi-box-shadow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item>

          <v-list-item to="/training">
            <v-list-item-icon>
              <v-icon>mdi-box-shadow</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              >DMPA-SC Self-Paced Online Training Dashboard</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/trainees">
            <v-list-item-icon>
              <v-icon>mdi-box-shadow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Trainees</v-list-item-title>
          </v-list-item>
          <v-list-item to="/publicdashboard">
            <v-list-item-icon>
              <v-icon>mdi-box-shadow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Public Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item to="/serviceDirectory">
            <v-list-item-icon>
              <v-icon>mdi-box-shadow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Service Directory</v-list-item-title>
          </v-list-item>
          <v-list-item to="/notifications">
            <v-list-item-icon>
              <v-icon>mdi-box-shadow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Notifications</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/store/actions.type";
import { version } from "../../package";
export default {
  name: "AppBar",
  data() {
    const spacers = Array.from({ length: 17 }, (val, i) => `${i}`);
    const nspacers = Array.from({ length: 16 }, (val, i) => `n${i + 1}`);
    const defaults = ["auto", ...spacers];

    return {
      directions: ["t", "b", "l", "r", "s", "e", "x", "y", "a"],
      marginDirection: "a",
      marginSize: "2",
      marginSizes: [...defaults, ...nspacers],
      paddingDirection: "a",
      paddingSize: "6",
      paddingSizes: defaults,
      playgroundText:
        "Use the controls above to try out the different spacing helpers.",
      drawer: false,
      group: null,
      appVersion: version,
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
    computedPadding() {
      return `p${this.paddingDirection}-${this.paddingSize}`;
    },
    computedMargin() {
      return `m${this.marginDirection}-${this.marginSize}`;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
};
</script>
