<template>
  <v-app id="app">
    <!-- Sizes your content based upon application components -->
    <!-- <SystemBar v-if="isAuthenticated && isMasterDataExists"></SystemBar> -->
    <PWAPrompt></PWAPrompt>
    <AppBar v-if="isAuthenticated"></AppBar>
    <v-main
      class="d-flex mt-5"
      v-bind:class="{
        'align-center justify-center': !isAuthenticated,
      }"
    >
      <!-- Provides the application the proper gutter -->
      <div class="mt-5 mr-5 ml-5">
        <!-- If using vue-router -->
        <router-view></router-view>
      </div>
    </v-main>

    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      An update is available
      <v-btn text @click="refreshApp"> Update </v-btn>
    </v-snackbar>
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.v-select__selection--comma {
  font-size: 13px;
}
.v-list-item .v-list-item__subtitle,
.v-list-item .v-list-item__title {
  font-size: 12px;
}
</style>
<script>
import update from "@/mixins/update";
import { mapGetters } from "vuex";
import AppBar from "@/components/AppBar.vue";
import PWAPrompt from "@/components/PWAPrompt.vue";

// import SystemBar from "@/components/SystemBar.vue";
import { version, releaseDate } from "../package";
export default {
  name: "App",
  data() {
    return {
      worker: null,
      isLogin: true,
      hasError: false,
      appVersion: version,
      releaseDate: releaseDate,
    };
  },
  mixins: [update],
  components: {
    AppBar,
    PWAPrompt,
  },
  metaInfo: {
    title: "miladycms",
    titleTemplate: "%s | MiLady CMS",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  created() {},
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {},
};
</script>
