export const FETCH_END = "setArticles";
export const FETCH_START = "setLoading";
export const PURGE_AUTH = "logOut";
export const SET_ARTICLE = "setArticle";
export const SET_AUTH = "setUser";
export const SET_COMMENTS = "setComments";
export const SET_ERROR = "setError";
export const SET_PROFILE = "setProfile";
export const SET_TAGS = "setTags";
export const TAG_ADD = "addTag";
export const TAG_REMOVE = "removeTag";
export const UPDATE_ARTICLE_IN_LIST = "updateArticleInList";
export const RESET_STATE = "resetModuleState";
export const SET_MASTERDATA = "setMasterData";
export const REMOVE_MASTERDATA = "removeMasterData";
export const SET_SETUPDATA = "setSetupData";
export const PURGE_SETUPDATA = "resetSetupData";
export const SET_OUTLETLIST = "setOutletList";
export const REMOVE_OUTLETLIST = "removeOutletList";
export const SET_OUTLETQUERY = "setOutletQuery";
export const RESET_OUTLETQUERY = "resetOutletQuery";
export const SET_CURRENT_OUTLET = "setCurrentOutlet";
export const SET_INTERVIEW_QUESTIONSET = "setInterviewQuestionSet";
export const STORE_ANSWER = "storeAnswer";
export const SAVE_SALE = "saveSale";
export const CLEAR_ACTIVITY = "clearActivity";
export const STORE_SALES = "storeSales";
export const SET_OUTLET_INQUIRY_DATASET = "setOutletInquiryDataSet";
export const STORE_INQUIRY = "storeInquiry";
export const CLEAR_INQUIRY = "clearInquiry";
export const SET_TOTALUSERCOUNT = "setTotalUserCount";
