var axios = require("axios");
import { API_URL } from "@/common/config";
import JwtService from "@/common/jwt.service";

import {
  FETCH_QUESTIONLIST,
  GET_QUESTION,
  GET_QUESTION_DETAIL,
  UPDATE_QUESTION,
  CREATE_QUESTION,
  DELETE_QUESTION,
  FETCH_QUESTION_SET_LIST
} from "./actions.type";

const initialState = {
  questionList: [],
  currentOutlet: {},
};

export const state = { ...initialState };

export const actions = {
  async[FETCH_QUESTIONLIST]() {
    // console.log("TOKEN: " + JwtService.getToken())
    var config = {
      method: 'get',
      url: `${API_URL}/v1/question`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_QUESTION_SET_LIST]() {
    // console.log("TOKEN: " + JwtService.getToken())
    var config = {
      method: 'get',
      url: `${API_URL}/v1/questionset/list`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[GET_QUESTION](context, postID) {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/question/${postID}`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      }
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[GET_QUESTION_DETAIL](context, questionId) {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/question/detail/${questionId}`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      }
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[CREATE_QUESTION](context, payload) {
    var postData = payload.questionData;
    console.log(postData);
    var config = {
      method: 'post',
      url: `${API_URL}/v1/question`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: postData
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[UPDATE_QUESTION](context, payload) {
    var questionData = payload.questionData;
    var config = {
      method: 'put',
      url: `${API_URL}/v1/question`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: questionData
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[DELETE_QUESTION](context, payload) {
    var config = {
      method: 'delete',
      url: `${API_URL}/v1/question/delete`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: payload
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
};


export default {
  state,
  actions
};
