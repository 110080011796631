var axios = require("axios");
import { API_URL } from "@/common/config";
import JwtService from "@/common/jwt.service";
import {
  FETCH_LESSONLIST,
  GET_LESSON,
  UPDATE_LESSON,
  DELETE_LESSON,
  GET_REF_TRAINING_DATA,
  CREATE_LESSON,
  FETCH_TRAINEE_LIST,
  FETCH_TRAINEE_DETAIL,
  FETCH_PROVIDER_TYPE_LIST,
  FETCH_LESSON_QUIZ_LIST,
  FETCH_LESSON_VIDEO_LIST,
  GET_LESSON_DETAIL
} from "./actions.type";

const initialState = {
  outletList: [],
  outletQuery: {},
  currentOutlet: {},
};

export const state = { ...initialState };

export const actions = {
  async[FETCH_LESSONLIST]() {
    // console.log("TOKEN: " + JwtService.getToken())
    var config = {
      method: 'get',
      url: `${API_URL}/v1/lesson/list`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[GET_LESSON](context, payload) {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/lesson/${payload.lessonId}/${payload.languageId}`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      }
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[GET_LESSON_DETAIL](context, payload) {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/lesson/detail/${payload.lessonId}`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      }
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_PROVIDER_TYPE_LIST](context) {
    console.log(context)
    var config = {
      method: 'get',
      url: `${API_URL}/v1/training/providertypes`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      }
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[UPDATE_LESSON](context, payload) {
    var lessonData = payload.lessonData;
    var config = {
      method: 'put',
      url: `${API_URL}/v1/lesson`,
      headers: {
        //'Content-Type': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: lessonData
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[CREATE_LESSON](context, payload) {
    var lessonData = payload.lessonData;
    console.log(lessonData)
    var config = {
      method: 'post',
      url: `${API_URL}/v1/lesson`,
      headers: {
        // 'Content-Type': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: lessonData
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[DELETE_LESSON](context, lessonId) {
    var payload = {
      lessonId: lessonId
    }
    var config = {
      method: 'delete',
      url: `${API_URL}/v1/lesson/delete`,
      headers: {
        // 'Content-Type': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_LESSON_QUIZ_LIST]() {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/lesson/questions`,
      headers: {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + JwtService.getToken()
      }
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_LESSON_VIDEO_LIST]() {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/lesson/videos`,
      headers: {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + JwtService.getToken()
      }
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },

  async[GET_REF_TRAINING_DATA]() {
    var config = {
      method: 'get',
      url: `${API_URL}/refdata/training`,
      headers: {
        'Content-Type': 'text/plain'
      }
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TRAINEE_LIST](context, payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/training/trainees`,
      headers: {
        // 'Content-Type': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TRAINEE_DETAIL](context, payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/training/trainee`,
      headers: {
        // 'Content-Type': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
};

const getters = {
};

export default {
  state,
  actions,
  mutations,
  getters
};
