var axios = require("axios");
import { API_URL } from "@/common/config";
import JwtService from "@/common/jwt.service";
import Vue from "vue";
import {
  FETCH_POSTLIST,
  GET_POST,
  UPDATE_POST,
  DELETE_POST,
  GET_REF_DATA,
  CREATE_POST,
  UPLOAD_MEDIA,
  FETCH_POST_SLUG_LIST
} from "./actions.type";
import {
  SET_OUTLETLIST,
  REMOVE_OUTLETLIST,
  SET_OUTLETQUERY,
  RESET_OUTLETQUERY,
  SET_CURRENT_OUTLET,
} from "./mutations.type";

const initialState = {
  outletList: [],
  outletQuery: {},
  currentOutlet: {},
};

export const state = { ...initialState };

export const actions = {
  async[FETCH_POSTLIST]() {
    // console.log("TOKEN: " + JwtService.getToken())
    var config = {
      method: 'get',
      url: `${API_URL}/v1/downloadposts`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_POST_SLUG_LIST]() {
    // console.log("TOKEN: " + JwtService.getToken())
    var config = {
      method: 'get',
      url: `${API_URL}/v1/posts/slugs`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },

  async[GET_POST](context, postID) {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/post/${postID}`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      }
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[UPDATE_POST](context, payload) {
    var postData = payload.postData;
    var config = {
      method: 'post',
      url: `${API_URL}/v1/post/${postData.id}`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: postData
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[CREATE_POST](context, payload) {
    var postData = payload.postData;
    console.log(postData)
    var config = {
      method: 'post',
      url: `${API_URL}/v1/posts`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: postData
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[DELETE_POST](context, payload) {
    var config = {
      method: 'delete',
      url: `${API_URL}/v1/posts`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken()
      },
      data: payload,
    };
    return await new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[GET_REF_DATA]() {
    var config = {
      method: 'get',
      url: `${API_URL}/refdata`,
      headers: {
        'Content-Type': 'text/plain'
      }
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[UPLOAD_MEDIA](context, payload) {
    const formData = new FormData();
    formData.append('upload', payload);
    var config = {
      method: 'post',
      url: `${API_URL}/admin/media/upload`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  [SET_OUTLETQUERY](state, outletquery) {
    state.outletQuery = outletquery;
    localStorage.outletQuery = JSON.stringify(outletquery);
  },
  [SET_OUTLETLIST](state, outletlist) {
    state.outletList = outletlist;
    localStorage.outletList = JSON.stringify(outletlist);
  },
  [RESET_OUTLETQUERY](state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
    localStorage.removeItem("outletQuery");
    localStorage.removeItem("outletList");
  },
  [REMOVE_OUTLETLIST]() {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
    localStorage.removeItem("outletQuery");
    localStorage.removeItem("outletList");
  },
  [SET_CURRENT_OUTLET](state, selectedOutlet) {
    state.currentOutlet = selectedOutlet;
    state.checkIn = new Date().toISOString();
    selectedOutlet.checkIn = state.checkIn;
    localStorage.currentOutlet = JSON.stringify(selectedOutlet);
  }
};

const getters = {
  outletList() {
    if (localStorage.getItem("outletList") != null) {
      return JSON.parse(localStorage.outletList);
    } else {
      return {};
    }
  },
  currentOutlet() {
    if (localStorage.getItem("currentOutlet") != null) {
      return JSON.parse(localStorage.currentOutlet);
    } else {
      return {};
    }
  },
  outletQuery() {
    if (localStorage.getItem("outletQuery") != null) {
      return JSON.parse(localStorage.outletQuery)
    } else {
      return {};
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
