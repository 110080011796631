var axios = require("axios");
import { API_URL } from "@/common/config";
import JwtService from "@/common/jwt.service";

import {
  FETCH_COUNTRY_LIST,
  FETCH_TOTALUSERCOUNT,
  FETCH_TOTAL_USER_COUNTRY_COUNT_PUBLIC_REPORT,
  FETCH_TOTALUSERCOUNTFISCAL,
  FETCH_TEST_RESULT_COUNT,
  FETCH_TRAINING_STARTED_COUNT,
  FETCH_SUCCESS_TEST_RESULT_COUNT,
  FETCH_TOTAL_LECTURE_COMPLETED_COUNT,
  FETCH_QUESTION_CORRECT_COUNT,
  FETCH_PROGRAM_SPENDING_TIME
} from "./actions.type";

import {
} from "./mutations.type";

const initialState = {
  questionList: [],
  currentOutlet: {},
};

export const state = { ...initialState };

export const actions = {
  async[FETCH_COUNTRY_LIST](context, payload) {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/report/countryList/${payload.userType}`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TOTALUSERCOUNT]() {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/report/totalusercount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // context.commit(SET_TOTALUSERCOUNT, response.data);
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TOTAL_USER_COUNTRY_COUNT_PUBLIC_REPORT]() {
    var config = {
      method: 'get',
      url: `${API_URL}/v1/report/totalcountryusercount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: {}
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // context.commit(SET_TOTALUSERCOUNT, response.data);
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TOTALUSERCOUNTFISCAL](context, payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/report/totalfiscalusercount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TEST_RESULT_COUNT](context,payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/report/totaltestresultcount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TRAINING_STARTED_COUNT](context,payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/report/totaltrainingcount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_SUCCESS_TEST_RESULT_COUNT](context,payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/report/totalposttestcount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_TOTAL_LECTURE_COMPLETED_COUNT](context,payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/report/totallecturecompleted`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_QUESTION_CORRECT_COUNT](context, payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/report/totalquestioncount`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_PROGRAM_SPENDING_TIME](context,payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/report/totalprogramspendingtime`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
        //'Access-Control-Allow-Origin': '*'
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
};

export const mutations = {
  // [SET_TOTALUSERCOUNT](state, totalData) {
  //   localStorage.totalUserCount = JSON.stringify(totalData);
  // },
}


export default {
  state,
  actions
};
