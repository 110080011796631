import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./auth.module"
import post from "./post.module"
import training from "./training.module"
import activity from "./activity.module"
import inquiry from "./inquiry.module"
import question from "./question.module"
import user from "./user.module"
import report from "./report.module"
import serviceDirectory from "./servicedirectory.module"
import publicReport from "./publicreport.module"
import notification from "./notifications.module"

Vue.use(Vuex)

export default new Vuex.Store({
  // strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    post,
    activity,
    inquiry,
    training,
    question,
    user,
    report,
    serviceDirectory,
    publicReport,
    notification
  }
})
