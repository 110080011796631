var axios = require("axios");
import { API_URL } from "@/common/config";
import JwtService from "@/common/jwt.service";
import {
  FETCH_USERLIST,
  FETCH_USER_DETAIL,
  FETCH_PROVIDER_DETAIL,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  SET_PASSWORD
} from "./actions.type";

const initialState = {
  userList: []
};

export const state = { ...initialState };

export const actions = {
  async[FETCH_USERLIST](context, payload) {
    var data = payload;
    console.log(data);
    var config = {
      method: 'post',
      url: `${API_URL}/v1/users`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
      },
      data: data
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_USER_DETAIL](context, payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/user/detail`,
      headers: {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + JwtService.getToken(),
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[CREATE_USER](context, payload) {
    var newUser = payload;
    var config = {
      method: 'post',
      url: `${API_URL}/v1/user`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),

      },
      data: newUser
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[UPDATE_USER](context, payload) {
    var updateData = payload;
    var config = {
      method: 'put',
      url: `${API_URL}/v1/user`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
      },
      data: updateData
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[SET_PASSWORD](context, payload) {
    var updateData = payload;
    var config = {
      method: 'post',
      url: `${API_URL}/v1/user/setpassword`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),
      },
      data: updateData
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[DELETE_USER](context, payload) {
    var userData = payload;
    var config = {
      method: 'delete',
      url: `${API_URL}/v1/user`,
      headers: {
        'Content-Type': 'text/plain',
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JwtService.getToken(),

      },
      data: userData
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  },
  async[FETCH_PROVIDER_DETAIL](context, payload) {
    var config = {
      method: 'post',
      url: `${API_URL}/v1/provider/detail`,
      headers: {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + JwtService.getToken(),
      },
      data: payload
    };
    return new Promise(resolve => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data)
        })
        .catch(function (error) {
          console.log(error)
          resolve(error)
        });
    });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
};

const getters = {
};

export default {
  state,
  actions,
  mutations,
  getters
};
