import ApiService from "@/common/api.service";
import {
  FETCH_OUTLET_INQUIRY_DATASET, ADD_INQUIRY
} from "./actions.type";
import {
  SET_OUTLET_INQUIRY_DATASET, SET_ERROR, STORE_INQUIRY, CLEAR_INQUIRY
} from "./mutations.type";

const initialState = {
  questionSet: []
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_OUTLET_INQUIRY_DATASET](context) {
    return new Promise(resolve => {
      ApiService.get("/v1/outletinquiry/inquiryMasterData")
        .then(({ data }) => {
          context.commit(SET_OUTLET_INQUIRY_DATASET, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  async [ADD_INQUIRY](context, activityData) {
    return new Promise(resolve => {
      ApiService.postActivity("/api/OutletInquiry", activityData)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          resolve(response);
          context.commit(SET_ERROR, response);
        });
    });
  }
}

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  [SET_OUTLET_INQUIRY_DATASET](state, inquiryMasterData) {
    state.inquiryMasterData = inquiryMasterData;
    localStorage.inquiryData = JSON.stringify([]);
    localStorage.inquiryMasterData = JSON.stringify(inquiryMasterData);
  },
  [STORE_INQUIRY](state, inquiry) {
    state.inquiryData = inquiry;
    localStorage.inquiryData = JSON.stringify(inquiry);
  },
  [CLEAR_INQUIRY]() {
    localStorage.removeItem("inquiryOutletStatus");
    localStorage.removeItem("inquiryRating");
    localStorage.removeItem("inquiryVisibilityList");
    localStorage.removeItem("inquirySellingPriceList");
    localStorage.removeItem("inquiryStockSources");
    localStorage.removeItem("inquiryOffTakeList");
    localStorage.removeItem("inquiryOnShelfStockList");
  }
};

const getters = {
  inquiryMasterData() {
    return JSON.parse(localStorage.inquiryMasterData);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
